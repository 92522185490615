import { Environment } from 'goodmaps-sdk';

const config = {
  TRACKING_ID: '',
  NAME: 'Staging',
  API_URL: 'https://stage.api.goodmaps.io/servicesv2-services',
  BUILDING_URL: 'https://stage.api.goodmaps.io/servicesv2-buildingmanagement/building',
  LINT_BUILDING_URL: 'https://stage.api.goodmaps.io/servicesv2-lint',
  IMDF_BUILDING_URL: 'https://stage.api.goodmaps.io/servicesv2-imdf',
  ENV_ID: Environment.Stage,
  userPoolId: 'us-east-2_3NepvH2Tl',
  userPoolWebClientId: '29u3n8nhg2knhdic92hua9ebl8',
  region: 'us-east-2',
};

export default config;
